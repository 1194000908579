<div *ngIf="showMainPage" class="card">
  <h3>Application Details MDU</h3>

  <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
    <div class="form-group row">
      <div class="form-group col-md-12">
      <label class="lable-css radio-inline" for="FJL required">Type of Work:
      </label>
      <input class="radio-inline" formControlName="workType" (change)="radioSelect()" type="radio" id="option1"
        value="LFN" />
      <label class="lable-css radio-inline" for="LFN">LFN </label>
      <!-- <input class="radio-inline" formControlName="workType" (change)="radioSelect()" type="radio" id="option2"
        value="DFN" />
      <label class="lable-css radio-inline" for="DFN">DFN</label> -->
      </div>
      
    </div>
    <div class="form-group row">
      <div class="form-group col-md-6">
        <label class="lable-css">UFA Category:</label>
        <select formControlName="ufaCategory" id="ufaCategory" class="form-control">
          <option value="" selected>Select</option>
          <option value="Low">Low</option>
          <option value="Medium">Medium</option>
          <option value="High">High</option>
        </select>
      </div>
      <div class="form-group col-md-6">
        <label for="ada" class="lable-css">Dwelling Type:</label>
        <select formControlName="dwelling_type" id="dwelling_type" class="form-control">
          <option value="" selected>Select</option>
          <option value="SDU">SDU</option>
          <option value="MDU">MDU</option>
          <option value="HMDU">HMDU</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      
      <div class="form-group col-md-6">
        <label class="lable-css">Architecture Type:</label>
        <select formControlName="architectureType" id="architectureType" class="form-control">
          <option value="" selected>Select</option>
          <option *ngFor="let architectureType of architectureOptions;" [ngValue]="architectureType">
            {{architectureType}}</option>

        </select>
      </div>
      <div class="form-group col-md-6">
        <label for="ada" class="lable-css">ADA ID:</label>
        <input type="text" id="ada" formControlName="adaId" name="ada" placeholder="Enter ADA ID" required />
      </div>
    </div>
    <div class="form-group row">
      <div class="form-group col-md-6">
        <label for="ada" class="lable-css">BRQ ID:</label>
        <input type="text" id="ror" formControlName="rorId" pathname="ror" placeholder="Enter BRQ ID" required />
      </div>
      <div class="form-group col-md-6">
        <label for="mps_boundary" class="lable-css">MPS Boundary:</label>
        <input type="text" id="mps_boundary" formControlName="mps_boundary" name="mps_boundary" placeholder="Enter MPS Boundary" />
      </div>
      
    </div>
    <div class="form-group row">
      <div class="form-group col-md-6">
        <label for="ada" class="lable-css">AOI ID:</label>
        <input type="text" id="aoi" formControlName="aoiId" pathname="aoi" placeholder="Enter AOI ID" required />
      </div>
      <div class="form-group col-md-6">
        <div class="form-group col-md-6">
          <label for="ada" class="lable-css">Budi Type:</label>
          <select formControlName="budi_type" id="budi_type" class="form-control">
            <option value="" selected>Select</option>
            <option value="PCD">PCD</option>            
            <option value="FDT">FDT</option>
            <option value="BFD_S">BFD S</option>
            <option value="BFD_M">BFD M</option>
          </select>
      </div>
      </div>
      
    </div>
    
    <div class="form-group row">
      <div class="form-group col-md-6">
        <app-upload-file [flowName]="flowName" (uploaded)="fileUploaded($event)"></app-upload-file>
        <!-- <label for="loc" class="lable-css">LOC ID:</label> -->
        <!-- <input type="file" id="loc" formControlName="locId" name="loc" placeholder="Upload LOC ID File" required /> -->
        <br />
        <p>Sample Location File :  <span (click)="downloadFile()">ADA_Location.xlsx  (3BMT-05-53_Location.xlsx)</span></p>
      </div>
      <!-- <div class="form-group col-md-6">
        <label for="ada" class="lable-css">BTD Type:</label>
        <select formControlName="btd_type" id="btd_type" class="form-control">
          <option value="" selected>Select</option>
          <option value="1G">1G</option>
          <option value="10G">10G</option>
        </select>
      </div> -->
    </div>
    @if (showLFNSubmit) {
    <button type="submit" [class]="myForm.invalid ? 'gray-button' : ''" [disabled]="myForm.invalid">
      Submit
    </button>
    }
  </form>
  
</div>
<!-- DFN Component Called-->
<div *ngIf="showMessage" class="card">
        <app-message 
            [showMessage]="showMessage"
            [MessageToShow]="MessageToShow"
            url={{url}}
            msg={{msg}}
            filename={{filename}}
        >
        </app-message>
</div>
<!-- spinner Component Called-->
<div *ngIf="isLoading">
  <ngx-spinner type = "ball-scale-multiple"></ngx-spinner> 
</div>
import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../api.service";
import { UploadFileComponent } from "../../components/common/upload-file/upload-file.component";
import { MessageComponent } from "../../components/common/message/message.component";

@Component({
  selector: "app-landing-page-mdu",
  standalone: true,
  imports: [
      ReactiveFormsModule,
      CommonModule,
      NgxSpinnerModule,
      UploadFileComponent,
      MessageComponent
    ],
  templateUrl: "./landing-page-mdu.component.html",
  styleUrls: ["./landing-page-mdu.component.scss"]
})

export class LandingPageMduComponent implements OnInit {
  
    islogin: boolean = true;
    myForm!: FormGroup;
    showDFNCard: boolean = false;
    showDFLFields: boolean = false;
    showDFLFieldsDFN: boolean = true;
    showLFNSubmit: boolean = false;
    showMainPage: boolean = true;
    isLoading: boolean = false;
    query: string = '';
    success_path: boolean = false;
    showTable: boolean = false;
    tableData: any;
    showMDUCard: boolean = false;
    mduPayLoad: any;
    showADRCard: boolean = false;
    adrPayLoad: any;
    showEquipmetCard: boolean = false;
    equPayLoad: any;
    showMessage: boolean = false;
    MessageToShow: any;
    upstream_screen: boolean = false;
    showEndEquipment: boolean = false;
    smp_connectivity_flag: boolean = false;
    bjl_structure_flag: boolean = false;
    bjl_flag: boolean = false;
    flowName: string = 'MDU';
    file: string | null = null; 
    default: string = 'Type2';
    architectureOptions: string[] = ['Type2', 'Type3'];
    url: string = '';
    filename: string = '';
    msg: string = ''; 
    
    constructor(
      private fb: FormBuilder,
      private http: HttpClient,
      private toastr: ToastrService,
      private apiService: ApiService,
      private spinner: NgxSpinnerService,
      private router: Router
    ) {
      this.myForm = this.fb.group({
        ufaCategory: ['', Validators.required],
        architectureType: ['', Validators.required],
        rorId: ['', Validators.required],
        //locId: ['', Validators.required],
        adaId: ['', Validators.required],
        workType: ['', Validators.required],
        mps_boundary: ['', Validators.required],
        locId: [''],
        dwelling_type: ['', Validators.required],
        aoiId: ['', Validators.required],
        budi_type: ['', Validators.required],
      });
  
      
    }
  
  
    ngOnInit() {
      
    }
  
      fileUploaded(event: string) {
      this.file = event;
      }
      
    onSubmit() {
      // onsubmit check if request is for LFN or DFN
      if (this.myForm.value.workType == 'DFN') {
        this.showDFNCard = true;
        this.showMainPage = false;
      } else {
        this.callCreateMapInfo();
      }
    }
  
    callCreateMapInfo() {
      // callCreateMapInfo call the api and based on api response initiate next flow like MDU, Equipment, etc. .
      this.isLoading = true;
      this.spinner.show();
      console.log('Form data submitted:', this.myForm.value);
      this.query = `?ufa_category=${this.myForm.value.ufaCategory}&architecture_type=${this.myForm.value.architectureType}&ror_id=${this.myForm.value.rorId}&loc_id=${this.myForm.value.locId}&ada_id=${this.myForm.value.adaId}&mps_boundary=${this.myForm.value.mps_boundary}&loc_id_file=${this.file}&dwelling_type=${this.myForm.value.dwelling_type}&aoi_id=${this.myForm.value.aoiId}&budy_type=${this.myForm.value.budi_type}`;      
      //this.query += `&s18_path=C:\\Users\\deepika.goswami\\Downloads\\odms mdu\\sample 2\\BRQ000000024008`
      this.apiService.initiatemapinfodesignMDU(this.query).subscribe({
        next: (response) => {
  
          if (response.message === 'Pre-signed url has been generated') {
            this.url = response.url;
            this.filename = response.file_name;
            this.msg = response.message;
            this.showMessage = true;
            this.showMainPage = false;
            this.MessageToShow = JSON.stringify(response);
          }else{
            if ('file_name' in response) {
              this.showMessage = true;
              this.showMainPage = false;
              this.MessageToShow = response;
            } else {
              this.toastr.error(response.message, 'Error Message');
              console.log('error');
            }
          }
          
          this.isLoading = false;
          this.spinner.hide();
          
        },
        error: (error) => {
          this.isLoading = false;
          this.spinner.hide();
          this.toastr.error(
            `The API returned Unsuccessfull response : ${error}`,
            'Error Message'
          );
        },
      });
    }
  
    radioSelect() {
      // Based on radio button enable and disable Architecture Type dropdown type2 value
      console.log('radioSelect');
      if (this.myForm.value.workType == 'DFN') {
        this.showDFNCard = true;
        this.showLFNSubmit = false;
        // this.selectedProperty = this.options[1];
        this.myForm.controls['architectureType'].setValue('Type3');
        this.myForm.controls['architectureType'].disable({ onlySelf: true });
      }
      else {
        this.showDFNCard = false;
        this.showLFNSubmit = true;
        this.myForm.value.architectureType = '';
        this.myForm.controls['architectureType'].setValue('');
        this.myForm.controls['architectureType'].enable({ onlySelf: true });
      }
    }
  
    disableMainForm() {
      // Disable main form page from DFN flow
      this.showMainPage = false;
    }
  
    downloadFile() {
      // Path to the file in the assets folder
      const fileUrl = 'assets/sample_file/ada_Location.xlsx';
  
      // Create an invisible anchor element
      const anchor = document.createElement('a');
      anchor.href = fileUrl;
      anchor.download = 'ADA_Location.xlsx'; // Set the file name for the download
  
      // Trigger a click event on the anchor to start the download
      anchor.click();
    }
  
    OnSelectProceedMap(data: any) { }
    receiveTableEventStructure(data: any) { }
    
    receiveTableEventUpstream(data:any){}
}

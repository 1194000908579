import { Routes } from '@angular/router';
import { LandingPageComponentADR } from './components_adr/landing-page/landing-page.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { LandingPageSd1Component } from './components_sd1/landing-page-sd1/landing-page-sd1.component';
import { LandingPageMduComponent } from './components_mdu/landing-page-mdu/landing-page-mdu.component';


export const routes: Routes = [

    {
        path: 'adr',
        component:LandingPageComponentADR

    },
    {
        path: '',
        component:LandingPageComponent
    },
    {
        path: 'sd1',
        component:LandingPageSd1Component
    },
    {
        path: 'mdu',
        component:LandingPageMduComponent
    }
];
